<template>
  <div class="mini-container columnd gap8">
    <h2 class="f18-700 fBold c-lightOrange text-start">Chats</h2>
    <div class="columnd allchats gap16 w100">
      <div
        v-for="(chat, index) in chats"
        :key="index"
        class="rowd align-center space-between w100 chat-container"
      >
        <div class="rowd gap4">
          <img
            class="img48"
            src="../../../assets/test/fakeprofile.svg"
            alt=""
          />
          <div class="columnd gap8">
            <span class="f14-700 c-ddGrey">Ryan Gaslin</span>
            <span class="f12-400 c-darkGrey">23june 12:33</span>
          </div>
        </div>
        <span v-if="chat.unread" class="unread f12-400 c-darkCryan">{{
          chat.unread
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatList",
  data() {
    return {
      chats: [
        {
          unread: 5,
        },
        {
          unread: 23,
        },
        {
          unread: 1,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
        {
          unread: 0,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  padding: 8px;
  border-radius: 8px;
  &:hover {
    background-color: var(--llGrey);
  }
}
.unread {
  border-radius: 4px;
  padding: 8px;
  background-color: var(--llGrey);
}
.mini-container {
  height: 100%;
  .allchats {
    max-height: 400px;
    overflow: scroll;
  }
}
</style>
